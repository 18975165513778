.wrapper {
    position: relative;
    --white: #e3e3e3;
    --bc: #ffffff31;
    --bc-al: #ffffffaf;
  }
  
  .cardSolutions {
    width: 99%;
    height: 100%;
    padding: 1rem 0;
    display: grid;
    grid-template-areas: "top" "bottom";
    grid-template-rows: 20% 1fr;
    border: 1px solid var(--bc-al);
    border-radius: .75rem;
    background: var(--bc-al);
    color: var(--white);
    backdrop-filter: blur(14px);
    box-shadow: 0 15px 30px -15px var(--bc-al);
    isolation: isolate;
    color: #0E7C9E;

  }
  
  .circle-1 {
    position: absolute;
    content: '';
    width: 8rem;
    height: 8rem;
    left: 1rem;
    top: 50%;
    transform: translate(-50%);
    border: solid 40px var(--bc);
    border-radius: 50%;
    z-index: -10;
  }
  
  .circle-2 {
    position: absolute;
    content: '';
    width: 3rem;
    height: 3rem;
    right: -1rem;
    top: 3rem;
    background-color: #E0AD4E;
    border-radius: 50%;
    z-index: -10;
    opacity: .9;
  }
  
  .top {
    padding: .5rem 4rem;
    z-index: 1;
    font-family: 'Golden Wings', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  .bottom {
    z-index: 2;
  }
  
  .users {
    display: flex;
    flex-direction: column;
    padding: .5rem 0;
  }
  
  .user {
    max-width: 100%;
    padding: .75rem 4rem;
    display: flex;
    gap: .1rem;
    flex-direction: column;
    align-items: flex;
    font-size: 1.1rem;
    border-bottom: 1px solid var(--bc-al);
    position: relative;
  }
  
  .user:hover {
    background-color: var(--bc-al);
  }
  
  .user:nth-last-child(1) {
    border-bottom: none;
  }
  
  .user::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    left: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--white);
    border-radius: 50%;
  }
  
  .user-name {
    font-weight: 500;
    font-style: italic;
  }
  
  .user-occupation {
    opacity: .8;
  }
  
  .u-l {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .soluciones-container {
    display: flex;
    justify-content: center;
    gap: 10em;
    padding: 5em 0em;
}

@media (max-width: 767px) {
.soluciones-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: scroll;
  gap: 4em;
  margin-left: 1em;
}
}