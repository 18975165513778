.blog-section {
    padding: 4rem 0;
    text-align: center;
}


.blog-posts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 auto;
}

.blog-post {
    width: 300px;
    min-height: 275px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.blog-post img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #eee;
    height: 150px;
    object-fit: cover;
}


.blog-post p {
    padding: 0 1rem 1rem;
    margin: 0;
    font-size: 0.9rem;
    color: #666; /* Ajusta el color si es necesario */
}

.blog-post:hover {
    transform: translateY(-5px);
}

.blog-section button {
    margin-top: 2rem;
    padding: 1rem 2rem;
}


/* Responsive Design */
@media (max-width: 768px) {
    .blog-posts {
        flex-direction: column;
    }

    .blog-post {
        width: 90%;
    }
}


