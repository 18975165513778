.differences-section {
    position: relative; /* Needed to position the pseudo-element */
    padding: 4rem 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: visible;
  }
  .differences-section::before {
    content: '';
    position: absolute;
    top: -15rem;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../Assets/IsoVerde.png");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5; /* Set the opacity to desired level, here it's 50% */
    z-index: -1; /* Place it behind the content */
    height: 140%;
  }
    
  p.differences-intro {
    width: 60%;
    text-align: center;
    margin: 3em auto;
}
  
  .differences-section h2 {
    margin-bottom: 2rem;

  }

  .differences-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 65%;
    margin: 0 auto;
}
  
  .service-card {
    width: 350px;
    height: 350px;
    padding: 1.5rem;
    background-color: #ffffffe8;
    border: none;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Stronger shadow for hover effect */
  }
  
  .service-icon {
    width: 130px;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 85px;

  }
  
  .service-card h3 {

    margin-bottom: 0.5rem; /* Adjust spacing to match the image */
  }
  
  .service-card p {
    font-size: 0.9rem; /* Adjust font size if necessary */
  }

  /* .differences-container > :nth-child(1), .differences-container > :nth-child(1),
  .differences-container > :nth-child(3), .differences-container > :nth-child(3) {
    top: 2em;
    position: relative;
  } */

  .differences-container > :nth-child(odd), .differences-container > :nth-child(odd) {
    top: 3em;
    position: relative;
  }
  @media (max-width: 768px) {
    .differences-container {
      flex-direction: column;
      align-items: center;
    }
    .differences-container {
        width: 99% !important;
      }
      .service-card {
        width: 90%;
        height: auto;
        padding: 0;
      }
      .differences-container > :nth-child(odd), .differences-container > :nth-child(odd) {
        top: 0;
        position: unset;
      }
      .service-card > * {
        padding: 1em;
      }
      .differences-section::before {
        background-size: auto 80%; /* Adjust the percentage to control the size and gap */
        background-repeat: repeat-y;
        /* If you want to add a fixed gap between images, you can use a linear-gradient as background image */
        background-image: linear-gradient(transparent, transparent 50%, url("../Assets/IsoVerde.png") 50%), url("../Assets/IsoVerde.png");
        background-size: 100%; /* Adjust the first value for image size, the second for the gap */
        background: none;
    }
  }
  