.mobile {
  display: none;
}

.desktop {
  display: flex;
}
@media (max-width: 767px) {
  .desktop {
      display: none;
  }

  .mobile {
      display: flex;
  }
}