.about-us {
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-top: 2em;

  }
  
  .innerCont {
    z-index: 2;
    position: relative;
    text-align: left;
    padding: 2em;
  }
  .imageText > .parallax-image,
  .imageText > .innerCont {
    box-sizing: border-box; /* Include padding and border in the element's width */
    flex: 1;
  }

  
  .parallax-image {
    height: 500px;
    width: 100%;
    background-image: url('https://themes.muffingroup.com/be/business7/wp-content/uploads/2023/03/bebusiness7-about-pic2.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .imageText {
    display: flex;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    box-shadow: -1px 31px 14px -14px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 10px 10px -10px rgb(0 0 0 / 10%);
}
.cardsSection {
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 1440px;
  margin: 2em auto;
}

@media (max-width: 767px) {
  .imageText {
    flex-direction: column-reverse;
  }
.imageText > .parallax-image{
  flex: auto;
}
.parallax-image {
  height: 370px;
}

}