.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 710px;
}
.hero-banner {
    width: 100%; /* Adjust based on your design */
    height: 100%; /* Adjust based on your design */
    position: relative;
    max-width: 1440px;
    box-shadow: -1px 31px 14px -14px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 10px 10px -10px rgb(0 0 0 / 10%);
}
  
.hero-banner img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* This will center the image perfectly */
    width: auto; /* Keep image aspect ratio */
    height: auto; /* Keep image aspect ratio */
    max-width: 100%; /* Image will not exceed the container's width */
    max-height: 100%; /* Image will not exceed the container's height */
    transition: opacity 0.5s ease-in-out;
    z-index: 1; /* Ensures images stay under the navigation and text */
  }

.hero-banner img.active {
    opacity: 1;
}
  
  .hero-text {
    position: absolute;
    top: 35%;
    left: 5%;
    color: white;
    z-index: 2;
    width: 50%;
  }
  .subtitle{
    color: #0E7C9E;
  }
  .navigationHero {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    z-index: 2;
    align-items: center;
  }

.nav-arrow {
  width: 48px; /* Increased width */
  height: 48px; /* Increased height */
  background: none;
  border: none;
  color: #76A581; /* Arrow color */
  font-size: 30px; /* Adjust font size as needed */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .nav-arrow:hover{
    color: #E0AD4E;
  }
  
  /* Styles for the dots */
  .navigation-dot {
    width: 48px; /* Increased size for better tap target */
    height: 48px; /* Increased size for better tap target */
    border-radius: 50%; /* Keeps it circular */
    background: #76A581; /* Default dot color */
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Styles for the active dot */
  .navigation-dot.active {
    background: #E0AD4E; /* Active dot color */
  }
  
  /* Add some space between the dots */
  .navigation-dot + .navigation-dot {
    margin-left: 10px;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    white-space: nowrap; /* added for safety */
  }
  


  .svg-animation {
    position: absolute;
    z-index: 1;
    top: -15%;
    right: -10%;
    opacity: .9;

}
  
  svg {
    height: 90vh;
  }
  
  .st0,
  .st1,
  .st2,
  .st3 {
    fill: none;
    stroke-width: 42;
    stroke-miterlimit: 10;
  }
  
  .st0 {
    stroke: #0E7C9E;
    stroke-dasharray: 12.1947, 12.1947, 12.1947, 12.1947, 12.1947, 12.1947;
  }
  
  .st1 {
    stroke: #76A581;
    stroke-dasharray: 50, 90, 200, 30, 40, 0;
  }
  
  .st2 {
    stroke: #E0AD4E;
    stroke-linecap: square;
    stroke-dasharray: 120, 20, 110, 20, 140;
  }
  
  .st3 {
    stroke: #E0AD4E;
    stroke-width: 16;
    stroke-linecap: square;
  }
  
  @media (max-width: 767px) {
    .hero-banner {
      display: flex;
      flex-direction: column-reverse;
  }
  svg {
    height: 70vh;
}
.svg-animation {
  position: absolute;
  z-index: 1;
  top: -10%;
  left: -26%;
  opacity: .9;
}
.hero-banner img {
  top: 25%;
}
.hero-text{
  top: 50%;
  left: 0;
  text-align: center;
  backdrop-filter: blur(3px);
  width: 100%;
}
.svg-animation {
  overflow-x: hidden; /* This prevents the SVG from overflowing horizontally */
  display: block; /* To prevent inline-block default spacing issues */
  width: 100%; /* Ensures that the container does not exceed the width of the parent */
  /* No height property here; let the SVG scale naturally */
}
.svg-animation {
  left: 0;
}

.svg-animation svg {
  max-width: 100%; /* This ensures that the SVG is not wider than its container */
  height: auto; /* Keeps the SVG's aspect ratio intact */
  overflow: hidden;
}

.hero-banner img {
  width: 100%; /* Scale the image to fit the width */
  height: auto; /* Allow the height to scale to maintain aspect ratio */
}
.hero-text h1{
  font-size: 1.3em;
}
.navigationHero {
  bottom: 60px;
}
  }