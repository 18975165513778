@font-face {
  font-family: 'Golden Wings';
  src: local('Golden Wings'), local('goldenwings'),
       url('./Assets/fonts/goldenwings.otf') format('opentype'),
       url('./Assets/fonts/goldenwings.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1,h2,h3 {
  font-family: 'Golden Wings', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #0E7C9E;

}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content {
  margin-top: 75px;
}

.ctaAll{
  background-color: #76A581;
  padding: 1em 2em;
  color: white;
  border: none;
  border-radius: 50px;
  font-family: 'Golden Wings', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  cursor: pointer;
}

.ctaAll:hover {
  background-color: #E0AD4E;
  font-weight: bolder;


}
h2{
  font-size: 2em;
}

.inHere,
.soluciones-container,
.Homepage .about-us,
.social-proof,
.Homepage .blog-section {
  background-color: #E0E2DB; 
}