.contact-form {
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.form-group {
    margin-bottom: 20px;
    text-align: center;

}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-color 0.3s;
    background-color: transparent;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: #aaa;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-bottom: 2px solid #0044cc;
}

.submit-button {
    width: 100%;
}

.fcomx {
    display: flex;
}

.multi-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    height: auto; /* Let it size dynamically based on the number of options displayed */
}

/* Styles for checkbox group */
.checkbox-group {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.form-group label {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
}

.form-group div {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group div label {
    display: flex;
    margin-bottom: 5px;
}

.checkbox-label {
    display: flex;
    align-items: center;
}

.checkbox-label input[type="checkbox"] {
    margin-right: 10px;
}
.DropF {
    position: absolute;
    left: 1em;
    width: 85%;
    height: 10em;
    overflow: scroll;
}
input[type="checkbox"] {
    width: auto;
    margin-right: 1em;
}
/* Styles for main category labels */
.form-group > label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 10px;
    text-align: left;
    color: #868585;
}

.form-group > label::after {
    content: '▼';
    /* Additional styles for the arrow */
}

.form-group > label.open::after {
    content: '▲';
    /* Additional styles for the arrow */
}

/* Ensure checkbox labels do not have arrows */
.checkbox-label, 
.form-group .DropF label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: flex-start;
}

.checkbox-label::after, 
.form-group .DropF label::after {
    content: none;
}



.DropF {
    position: absolute;
    left: 1em;
    width: 85%;
    height: 10em;
    overflow: scroll;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-btn {
    display: block;
    margin-top: 10px;
    background-color: white;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    float: right;
}
.tipo-de-seguro-label {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: none;
    transition: border-color 0.3s;
    background-color: transparent;
    color: #aaa;
    text-align: center;
}