h1{
    text-align: center;
}
.flex {
    display: flex;
    justify-content: center;
    gap: 2em;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 5em;
}
.flex50{
    width: 33.33%;
}
.inHere i.fas,
.flex i.fas {
    color: #E0AD4E;
    font-size: 2em;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;
    margin-right: 0.5em;
    
}
.fas:before  {
    vertical-align: sub;
}
.CenterFF{
    text-align: center;
}
.inHere h4,
strong {
    font-family: 'Golden Wings', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #0E7C9E;
    font-size: 1.4em;
}
p{
    color: #0E7C9E;
}
.svg-animation-Contact {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.svg-animation-Contact > svg{
    height: 100px;
}
img.logxs {
    width: 200px;
}

.mainC {
    width: 350px;
    height: auto;
    padding: 1.5rem;
    border: none;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,.05);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  
  .mainC:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Stronger shadow for hover effect */
  }
  
  .service-icon {
    width: 130px;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 85px;

  }
  
  .mainC h3 {

    margin-bottom: 0.5rem; /* Adjust spacing to match the image */
  }
  
  .mainC p {
    font-size: 0.9rem; /* Adjust font size if necessary */
  }

  @media (max-width: 768px) {
    .flex {
        display: flex;
        flex-direction: column;
    }
    .mainC {
        width: 85%;
        margin: 0 auto;
    }
  }