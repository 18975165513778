.card {
    width: 20em;
    height: 25em;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
  }
  
  .card-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: inherit;
    height: inherit;
    background: rgba(255,255,255,.05);
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    padding: 1em;
  }
  
  .card:hover {
    transform: scale(1.04) rotate(1deg);
  }
  
  .circle {
    display: none;
    width: 150px; /* Ajusta según sea necesario */
    height: 150px; /* Ajusta según sea necesario */
    border-radius: 50%;
    position: absolute;
    animation: move-up6 2s ease-in infinite alternate-reverse;
  
    border: 30px solid; /* Grosor del borde */
    border-color: #f6f6f6 #fafafa; /* Colores del borde */
    background: transparent; /* Fondo transparente */
    box-sizing: border-box; /* Incluye el borde en las medidas de width y height */
  }
  
  .circle:nth-child(1) {
    top: -50px;
    left: -70px;
  }
  
  .circle:nth-child(2) {
    bottom: -50px;
    right: -70px;
    animation-name: move-down1;
  }
  light {
    font-weight: 100;
    font-size: 16px;
}
.infoCardCont {
    display: flex;
    gap: 9em;
    overflow-x: scroll;
    padding: 5em 0em;
}
.cardsSection > div > div:nth-child(1){
    margin-left: 4em;
}
  @keyframes move-up6 {
    to {
      transform: translateY(-10px);
    }
  }
  
  @keyframes move-down1 {
    to {
      transform: translateY(10px);
    }
  }

  @media (max-width: 767px) {
    .card {
        width: 18em;
        height: 22em;
      }
      .infoCardCont {
        gap: 8em;
    }
    .cardsSection > div > div:nth-child(1){
        margin-left: 2em;
    }
  }