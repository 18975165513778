
  .blog-card {
    display: flex;
    margin-left: 2rem;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, .1);
    margin-bottom: 1.6%;
    background: #fff;
    line-height: 1.4;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
    width: 100%;
    height: 15em;
  }
  
  .blog-card a {
    color: inherit;
  }
  
  
  .blog-card:hover .photo {
    transform: scale(1.3) rotate(3deg);
  }
  
  .blog-card .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  
  .blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform .2s;
  }
  
  .blog-card .details,
  .blog-card .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }
  
  .blog-card .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left .2s;
    background: #0e7c9e8a;
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: .9rem;
  }
  
  .blog-card .details ul li {
    display: inline-block;
  }
  
  .blog-card .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
    text-align: left;
  }
  
  .blog-card .description h1,
  .blog-card .description h2 {
    font-family: Poppins, sans-serif;
  }
  
  .blog-card .description h1 {
    line-height: 1;
    margin: 0;
    font-size: 1.7rem;
  }
  
  .blog-card .description h2 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #a2a2a2;
    margin-top: 5px;
  }
  p.read-more.ctaAll {
    width: 8em;
    text-align: center;
}
  
  .blog-card p {
    position: relative;
    margin: 1rem 0 0;
  }
  
  .blog-card p:first-of-type {
    margin-top: 1.25rem;
  }
  
  .blog-card p:first-of-type:before {
    content: "";
    position: absolute;
    height: 5px;
    background: #E0AD4E;
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
  }
  
  .blog-card:hover .details {
    left: 0%;
  }
  .blog-posts {
    display: flex;
    align-items: flex-start; /* Align items to the start (left) */
    width: 100%; /* Adjust the width as needed */
    padding: 20px; /* Add padding if needed */
    max-width: 1440px;
}

  /* Media Queries and Alternate Card Style */
  @media (min-width: 740px) {
    .blog-card {
      flex-direction: row;
    }
  
    .blog-card .meta {
      flex-basis: 40%;
      height: auto;
    }
  
    .blog-card .description {
      flex-basis: 60%;
    }
  
    .blog-card .description:before {
      transform: skewX(-3deg);
      content: "";
      background: #fff;
      width: 30px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  
    .blog-card.alt {
      flex-direction: row-reverse;
    }
  
    .blog-card.alt .description:before {
      left: inherit;
      right: -10px;
      transform: skew(3deg);
    }
  
    .blog-card.alt .details {
      padding-left: 25px;
    }
  }
  
  @media (max-width: 768px) {
    .blog-card {
      padding: 0;
      margin: 0;
      height: auto;
  }
  .blog-posts {
    padding: 0;
    margin: 0;
}
  }