.blog-post-page {
    margin: 5em auto;
    max-width: 1440px;
    padding-top: 4em;
}
.blog-post-page img{
    height: 700px;
    object-fit: cover;
    width: 100%;
}
.share-buttons {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-end;
    gap: 3em;
    color: #0E7C9E;
}
.innerHtml p {
    color: black;
}
.innerHtml {
    box-shadow: -1px 31px 14px -14px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 10px 10px -10px rgb(0 0 0 / 10%);
    padding-bottom: 3em;
}
.blog-post-page  .flex {
    margin-top: 2em;
}
.flex50{
    position: relative;
}
@media (max-width: 767px) {
.flex50{
    width: 99% !important;
}
}