.testimonial-card {
    flex: 0 0 100%; /* Each card is as wide as the viewport */
    scroll-snap-align: center; /* Align to the center when scrolling */
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: #ffffffc0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 10px;
    margin: 20px;
    max-width: 55%;
    position: relative;
    height: 20em;
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
  }
  .testimonials-container {
    display: flex;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    width: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    padding-top: 3em;
}
.testimonials-container::-webkit-scrollbar {
    display: none;
  }
  
  .testimonial-image {
    height: auto;
    border-radius: 3%;
    margin-top: -4em;
    margin-left: 1.3em;
  }
  .testimonial-image img{
    width: 100%;
    border-radius: 3%;
  }
  
  .testimonial-content {
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-quote {
    font-size: 1.1em; /* Larger font size for the quote */
    font-weight: bold;
    margin-bottom: 15px;
    padding: 0em 2em;
  }
  
  .testimonial-author {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .testimonial-info {
    font-size: 0.8em;
    color: #777; /* Less prominent color */
  }
  
  .social-proof {
    display: flex;
    justify-content: center;
    margin: 4em 0em;
    box-shadow: -1px 31px 14px -14px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 10px 10px -10px rgb(0 0 0 / 10%);
  }
  .testimonials {
    display: flex;
    flex-direction: row;
    overflow: scroll;
}

.testimonial-card.active {
    filter: none;
  }
  
  .testimonial-card.inactive {
    filter: blur(6px);
  }
  button.move {
    position: absolute;
    margin-top: 10%;
    color: #76A581;
    font-size: 2em;
    background-color: transparent;
    border: none;
}
button.move:hover{
    color: #E0AD4E;
    font-size: 2.5em;
    font-weight: bold;
}

.moveNext {
    right: 20%;

}
.movePrev {
    left: 20%;

}

@media (max-width: 767px) {
    .testimonial-card {
        max-width: 75%;
        display: flex;
        flex-direction: column;
        height: 30em;
    }
    .testimonial-image {
        width: 55%;
        height: auto;
        margin-top: -1em;
        margin-left: 0em;
    }
    button.move {
        margin-top: 15%;
    }
    .moveNext {
        right: 15%;
    }
    .movePrev {
        left: 15%;
    }

    /* Enable horizontal scrolling for touch devices */
    .testimonials-container {
        overflow-x: auto;
        touch-action: pan-x;
    }
    .testimonial-card {
        flex: 0 0 100%;
        margin: 10px;
      }
}