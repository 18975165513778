.business-partners-section {
  text-align: center;
  margin: 20px 0;
  overflow: hidden;
}

.partner-logo {
  height: 100px; 
  margin-right: 50px; 
  flex: 0 0 auto; 
  display: flex;
  align-items: center;
}
.partner-logo img{
  width: 100%;
    height: auto;
    max-width: 150px;
    max-height: 100px;
}
.logos-container {
  display: flex;
  white-space: nowrap;
  animation: scrollLogo linear infinite;
}

@keyframes scrollLogo {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%); /* Adjust to -50% for duplicated array */
  }
}

@media (max-width: 740px) {
  .partner-logo img{
    width: auto;
    height: 70px;
  }

}
