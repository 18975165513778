.content {
    position: relative;
    z-index: 2;
    margin-bottom: 20em;
    background-color: #ececec;
    box-shadow: -1px 31px 14px -14px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 10px 10px -10px rgb(0 0 0 / 10%);
}
footer.App-footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
}
.App-footer {
    color: #0E7C9E; /* Light text */
    padding: 20px 0;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .footer-top,
  .footer-middle,
  .footer-bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    max-width: 90%;
    margin: 0 auto;
  }
  
  .footer-nav a,
  .social-media-links a,
  .legal-info a {
    color: #0E7C9E;
    text-decoration: none;
    margin-right: 15px;
  }
  
  .footer-nav a:hover,
  .social-media-links a:hover,
  .legal-info a:hover {
    text-decoration: underline;
  }
  
  .contact-info p,
  .social-media-links a,
  .legal-info address,
  .legal-info a {
    margin: 5px 0;
  }
  .legal-info {
    text-align: center;
}
  .footer-logo {
    width: 300px; /* Adjust as needed */
  }
address{
  font-size: 12px;
  font-style: italic;
}
.social-media-links {
  display: flex;
  align-items: center;
  gap: 1em;
}
.social-media-links i {
  font-size: 2em;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap; /* added for safety */
}

  /* Responsive Design */
  @media (max-width: 600px) {
    .footer-top,
    .footer-middle,
    .footer-bottom {
      flex-direction: column;
      text-align: center;
      overflow: hidden;
    }
  
    .footer-nav a,
    .social-media-links a,
    .legal-info a {
      margin: 5px 0;
    }
    .App{
      width: 100vw;
      overflow: hidden;
    }
    .content {
      margin-bottom: 30em;
    max-width: 100vw;
  }
  footer.App-footer {
    max-width: 100vw;
    overflow: hidden;
  }
  .footer-nav a{
      display: flex;
      flex-direction: column;
  }
  nav.footer-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    gap: 1.5em;
}
  }
  